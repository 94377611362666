// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

@mixin row-flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin dropdown-list {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  height: 100%;
}
body {
  overflow: auto;
  height: 100%;
}

.ban-button {
  color: #ffffff;
  background: $ban-color;
}

.lock-button {
  color: #ffffff;
  background: $lock-color;
}

.badge-transferred {
  background-color: #17129a;
}

.btn-transfer {
  &:hover {
    color: #fff;
  }
  color: #fff;
  background: #17129a;
  border-color: #17129a;
}

.btn-cancel-transfer {
  color: #fff;
  background: #e08332;
  border-color: #e08332;
  &:hover {
    color: #fff;
  }
}

.suspend-color {
  color: #ffffff;
  background: $warning-color;
}

.sidebar .sidebar-nav {
  background: linear-gradient(
    180deg,
    rgba(19, 215, 172, 1) 0%,
    rgba(13, 181, 252, 1) 100%
  );
  .nav {
    @media screen and (max-device-width: 1024px) {
      padding-bottom: 60px !important;
    }
  }
}

.modal-image-background-template {
  max-width: 1000px;
}

.sidebar .sidebar-minimizer {
  background-color: #0db5cf;
}

.sidebar .nav-title {
  background-color: #13d7ac;
}

.sidebar .nav-dropdown.open .nav-link {
  background: rgba(0, 0, 0, 0.15);
}

.sidebar .nav-link.active {
  background: rgba(0, 0, 0, 0.3) !important;
}

.sidebar .nav-link {
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
}

.sidebar .nav-link .nav-icon {
  color: #ffffff;
}

.create-manual-dw-button {
  background: #86073f linear-gradient(180deg, #9e5782, #86073f) repeat-x;
  border-color: #86073f;
}

.ql-snow .ql-tooltip {
  z-index: 9999;
}

.group-title {
  color: #999;
  cursor: default;
  display: block;
  font-size: 75%;
  font-weight: 500;
  margin-bottom: 0.25em;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}

button {
  &:disabled {
    cursor: not-allowed !important;
  }
}

.fake-link-style {
  color: #3a87ad;
  cursor: pointer;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.no-data-content {
  background-color: #fff;
  height: 500px;
  font-weight: bold;
  @include row-flex-center;
  font-size: 50px;
}

.nav {
  .nav-item {
    .nav-dropdown-items {
      .nav-item {
        .nav-link {
          padding-left: 8px;
        }
      }
    }
  }
}

.row-filter {
  align-items: flex-end;
}

.button-group-align-right {
  display: flex;
  justify-content: flex-end;
}

.table thead th {
  text-align: center;
}

table {
  .total-row-table {
    font-weight: bold;
  }
}

.flex-space-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-underline-pointer-cursor {
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-container {
  .welcome-to-dashboard {
    background-color: #20a8d8;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
  }
}

.card-header-bank-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editable-column {
  &:hover {
    padding: 6px;
    cursor: text;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    transition: background-color 0.25s ease;
  }
}

.table-bordered {
  thead > tr > th {
    &:focus {
      outline: none;
    }
  }
}

.clear-filter-btn-bank-account-list {
  float: right;
  margin: 16px 0;
  color: #ffffff;
}

.active-tab {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.active-tab-b {
  color: #199619 ;
  background-color: #a5e8a699;
  border-color: #199619  #199619 #199619;
  font-weight: bold;
}

.active-tab-c {
  color: #a50606 ;
  background-color: #e47676c4;
  border-color: #a50606  #a50606 #a50606;
  font-weight: bold;
}

.active-tab-b-selected {
  color: #199619 ;
}

.tab-payment-deposit {
  color: #199619;
  background-color: #a5e8a699;
  font-weight: bold;
}

.tab-payment-withdraw {
  color: #a50606 ;
  background-color: #e47676c4;
  font-weight: bold;
}

.sub-game-tab {
  text-transform: uppercase;
}

.required-field-error {
  color: red;
}

.asterisk-mandatory {
  color: red;
}

.main-label {
  font-weight: bold;
}

.limit-setting-input-group-text {

  .form-control {
    text-align: right;
  }
  & span {
    text-transform: uppercase;
    min-width: 220px;
  }
}

.text-align-right {
  text-align: right;
}

.bank-limit-setting-container {
  .card-body {
    overflow: auto;

    form {
      min-width: 300px;
    }
  }
}

.card-header {
  font-weight: bold;
}

.option-disabel {
  color: #cfcdcd;
}

.border-input {
  border: 1px solid red;
}

.select-search-field-member-list {
  max-width: 150px;
  margin-right: 16px;
}

.align-items-center-member-list {
  margin-top: 16px;
}

.bank-balance-container {
  table > tbody > tr > td {
    vertical-align: inherit;
  }
}

.active-tab-prom {
  color: #495057;
  background-color: #d1d1d1;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-request-management-container {

  .react-bootstrap-table-negative-mb {
    padding-bottom: 100px;
    margin-bottom: -100px;
    overflow-y: hidden;
    min-height: 400px !important;
  }

  .filter-form {
    padding: 16px 0;
  }

  .nav {
    .nav-item {
      // color: #495057;
      // border-color: #dee2e6 #dee2e6 #fff;
      // border-top-left-radius: 8px;
      // border-top-right-radius: 8px;

      // &:not(:first-child) {
      //   margin-left: 8px;
      // }
    }

    .active-tab {
      color: #495057;
      background-color: #ffffff;
    }

    .none-active-tab {
      color: #495057;
    }
  }

  .username-hover {
    display: flex;
    align-items: center;
    position: relative;
  }

  .last-deposit-approved-content {
    font-size: 12px;
    position: absolute;
    will-change: transform;
    transform: translate3d(12px, 5px, 0px);
    z-index: 1000;
    float: left;
    min-width: 11rem;
    padding: 0.5rem 8px;
    margin: 0.125rem 0 0;
    color: #212529;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    text-align: center;
    background-color: #4d5666;
  }

  .last-deposit-approved-content.up:before,
  .last-deposit-approved-content.up:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top: 0;
  }

  .last-deposit-approved-content.up:before {
    left: 15%;
    bottom: 100%;
    border-bottom-color: #584444;
    border-width: 12px;
  }
  .last-deposit-approved-content.up:after {
    left: 15%;
    bottom: 100%;
    border-bottom-color: #4d5666;
    border-width: 12px;
  }

  .list-banks-wrap {
    max-height: 400px;
    width: 280px;
    overflow-y: auto;
  }

  .list-different-banks {
    padding-bottom: 8px;
    padding-top: 8px;
    box-sizing: border-box;

    .list-banks {
      .bank-option {
        font-size: 12px;
        background-color: transparent;
        color: inherit;
        cursor: default;
        display: block;
        font-size: inherit;
        padding: 8px 12px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;

        &:hover {
          background-color: #deebff;
        }
      }
    }
  }

  .action-submit-btn {
    margin-top: 16px;
    @include row-flex-center;

    .reset-button {
      margin-left: 16px;
    }
  }

  .btn-group {
    .btn-secondary {
      font-size: 12px;
      background-color: #fff;
      border: none;
    }
  }
}

.btn-info {
  color: #fff;
  background: #1cb2de;

  &:hover {
    color: #fff;
  }
}

.btn-commission {
  overflow: hidden;
}

.cancel-button {
  background-color: #495058;
  border-color: #495058;
}

.member-list-container {
  table {
    min-height: 50px;
  }
}

.game-settings-container {

  .game-setting-note-row {
    border-bottom: 1px solid #cccccc;
    .game-setting-note {
      color: orange;
      margin: 8px 0;
    }
  }

  .list-tab-games {
    padding: 8px 0;
  }

  .form-group-game-setting {
    .input-group {
      flex-wrap: unset;
      input {
        min-width: 100px;
        max-width: 165px;
        text-align: right;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .kei-game-settings {
      .kei-col {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }

  .tab-content {
    overflow: auto;

    form {
      min-width: 300px;
    }
  }
}

.display-bank-list-container {
  table {
    td > .auto-data {
      @media screen and (max-device-width: 1024px) {
        width: 300px !important;
      } 
      padding: 30px;
    }
  }
}

.pool-create-container {
  .input-row {
    padding-left: 1.25rem;
    color: #5a5a5a;
  }

  .col-days-in-week {
    color: #5a5a5a;
  }
}

.pool-list-container {
  .card-header-pool-list {
    display: flex;
    justify-content: space-between;
  }
}

.no-permission-page-container {
  width: 100%;

  .no-permission-text {
    font-size: 24px;
    color: #f57c00;
  }

  .go-back-dashboard-btn {
    background-color: #009688;
  }
}

.result-list-container {
  .result-list-header {
    width: 100%;
    padding: 8px 16px 8px;
    background-color: white;

    .select-pools {
      width: 500px;
    }
  }
}

.add-result-modal-container {
  .result-numbers {
    letter-spacing: 8px;
  }
}

.scan-result-container {
  table {
    .list-nums {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .num-item {
        width: 30px;
        line-height: 30px;
        height: 30px;
        background-color: #c8ced3;
        border-radius: 4px;
      }
    }

    .bet-win-column {
      cursor: pointer;
      color: #20a8d8;
    }

    .total-paid-amount {
      color: #28a745;
    }

    .total-profit {
      color: #dc3545;
    }
  }

  .bet-number {
    height: 40px;
    line-height: 40px;
    background-color: #d9edf7;
    color: #3a87ad;
    letter-spacing: 8px;
  }

  .scan-detail-content {
    padding: 0 8px;

    .common-info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .common-info-table {
      border-top: 3px solid #dddddd;

      .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .list-scan-table {
    padding: 0 8px;

    .table-title {
      font-size: 18px;
      color: #468847;
    }
  }
}

.analyze-result-container {
  .result-numbers {
    letter-spacing: 8px;
  }

  .bet-number {
    height: 40px;
    line-height: 40px;
    background-color: #d9edf7;
    color: #3a87ad;
    letter-spacing: 8px;
  }

  .scan-detail-content {
    padding: 0 8px;

    .common-info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .common-info-table {
      border-top: 3px solid #dddddd;

      .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .list-scan-table {
    padding: 0 8px;

    .total-paid-amount {
      color: #28a745;
    }

    .total-profit {
      color: #dc3545;
    }

    .table-title {
      font-size: 18px;
      color: #468847;
    }
  }
}

.transaction-history-container {
  .username-row {
    background-color: #d9edf6;
    border-radius: 4px;
    padding: 8px;
    color: #128fcc;
  }

  .transaction-filter-row {
    align-items: flex-end;
  }

  .transaction-summary {
    padding: 8px;
    .transaction-summary-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
    }

    .transaction-summary-info {
      .transaction-summary-info-row {
        padding: 4px 0px;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid #e1e1e1;
        }

        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
      }
    }
  }
}

.win-loss-report-container {
  .win-loss-report-filter-container {
    .row-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .select__indicator {
      padding: 2px 8px;
    }

    .dropdown-select-multi {
      -webkit-box-align: center;
      align-items: center;
      background-color: rgb(255, 255, 255);
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;
      border-color: rgb(204, 204, 204);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      transition: all 100ms ease 0s;
      outline: 0px !important;
      min-height: 28.5px;
      padding: 0 8px;

      &:hover {
        border-color: rgb(179, 179, 179);
      }

      svg {
        width: 12px;
        height: 12px;
      }

      .content {
        .multi-input-item {
          background-color: rgb(230, 230, 230);
          display: flex;
          min-width: 0px;
          box-sizing: border-box;
          border-radius: 2px;
          margin: 2px;
        }
      }
    }

    .list-dropdown-wrap {
      background-color: hsl(0, 0%, 100%);
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
      margin-bottom: 8px;
      margin-top: 8px;
      position: absolute;
      width: 100%;
      z-index: 1;
      box-sizing: border-box;

      .list-dropdown {
        max-height: 300px;
        overflow-y: auto;
        padding: 8px 0;
        position: relative;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
      }

      .dropdown-item-custom {
        background-color: transparent;
        color: inherit;
        cursor: default;
        display: block;
        font-size: inherit;
        padding: 8px 28px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;

        &:active,
        &:hover {
          background-color: #b2d4ff;
        }
      }
    }

    .game-item {
      margin: 2px 0;
      padding: 0 16px;
    }
  }

  table {
    border: 1px solid #c8ced3 !important;
    tbody > tr > td > .transform-expand {
      transform: rotate(90deg);
      transition: transform 0.2s ease-in;
    }
    tbody > tr > td > .origin-expand {
      transform: rotate(0);
      transition: transform 0.2s ease-in;
    }
    tbody > tr > td > .row-table-td {
      display: flex;
      flex-direction: row;
    }

    tbody > tr > td > .row-table-td > span {
      margin-left: 5px;
    }
    
  }
}

.back-button {
  color: #ffffff;
  background: #495058;
  margin-bottom: 16px;
  width: 80px;
}

.fake-link-style {
  color: #20a8d8;
  cursor: pointer;
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bets-table-container {
  .bet-numbers-row {
    .col-item {
      @media (min-width: 1600px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      height: 30px;
      padding: 0;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      border: 1px solid #cccccc;
      justify-content: space-between;
      .number {
        min-width: max-content;
        width: 130px;
        padding: 0 4px;
        background-color: steelblue;
        @include row-flex-center;
      }

      .bet-number-and-position {
        position: relative;
        padding-right: 4px;
        color: rgb(179, 76, 76);
        cursor: pointer;
      }

      .username-amount-container {
        max-height: 350px;
        overflow: auto;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
          rgba(9, 30, 66, 0.31) 0px 0px 1px;
        width: 220px;
        border-radius: 0px 0px 4px 4px;
        font-size: 12px;
        background: #4d5666;

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
          border-top: 0;
        }

        &:before {
          left: 50%;
          bottom: 100%;
          border-bottom-color: #4d5666;
          border-width: 12px;
        }

        &:after {
          left: 50%;
          bottom: 100%;
          border-bottom-color: #4d5666;
          border-width: 12px;
        }
        .username-amount-list-row {
          border-top: 1px solid #cccccc;
          padding: 4px 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .total-footer-row {
    padding: 16px;
    margin-top: 24px;
    border: 1px solid #cccccc;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 200px);
    margin-left: -1px;
    margin-right: -1px;

    .row-item {
      display: flex;
      align-items: center;
    }
  }
}

.bets-list-container {
  .bets-list-filter-container {
    .card-body {
      .filter-row {
        align-items: flex-end;
      }
    }
  }
}

.bets-list-summary {
  padding: 32px 0;
  .col-summary {
    .summary-header {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .summary-body {
      .summary-row {
        border-top: 1px solid #dddddd;
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
      }
    }
  }
}

.lost-money-report-container {
  .summary-content {
    padding: 0 8px;

    .common-info-table {
      border-top: 3px solid #dddddd;

      .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }
  }
}

.bank-info {
  font-weight: bold;

  .bank-code {
    color: #333333;
  }

  .bank-id {
    color: #b94b49;
  }

  .bank-name {
    color: #3a87ad;
  }
}

.notification-wrapper {
  position: relative;

  a {
    color: inherit;
  }

  .notification-counter {
    position: absolute;
    top: -0.75em;
    right: -1em;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.periods-offline-online-container {
  table {
    .dropdown-select-multi {
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-color: rgb(204, 204, 204);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      min-height: 38px;
      position: relative;
      transition: all 100ms ease 0s;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: bold;
      outline: 0px !important;
      padding: 0 8px;

      &:focus {
        outline: 2px solid blue !important;
      }
    }

    .list-dropdown-wrap {
      @include dropdown-list;
      right: 0;
      @media screen and (max-device-width: 576px) {
        width: 65%;
      }
      width: 20%;
      text-transform: uppercase;
    }
  }
}

.referral-rate-container {
  .list-games-referral-rate {
    .game-row {
      padding: 8px 0;
      border-bottom: 1.5px solid #dddddd;

      &:nth-child(odd) {
        background-color: #f5f5f5;
      }

      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}

.referral-togel-container {
  .list-games-referral-togel {
    background-color: red;
    .game-row {
      padding: 8px 0;
      border-bottom: 1.5px solid #dddddd;

      &:nth-child(odd) {
        background-color: #f5f5f5;
      }

      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
}

.web-page-container {
  .web-page-part-wrap {
    overflow: auto;
    position: relative;
    .list-tabs {
      overflow-x: auto;
      position: relative;
      .tab-meta {
        cursor: pointer;
        padding: 15px 0px;
        text-align: center;
        min-width: 100px;
      }

      .active-tab {
        border-radius: 4px;
        color: rgb(3, 169, 244);
      }

      .tab-selection-bar-track {
        border-bottom: 2px solid #f0f0f0;
        height: 2px;
        left: 0px;
        bottom: 0px;
        width: 100%;
        position: absolute;
        .tab-selection-bar {
          // @media screen and (max-device-width: 768px) {
          //   display: none;
          // }
          transition: left 400ms ease 0s, right 400ms ease 0s, width 200ms ease 0s;
          background-color: rgb(3, 169, 244);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
          display: inline-block;
          height: 2px;
          left: 0px;
          position: absolute;
          top: 0px;
        }
      }
    }

    .ql-editor {
      min-height: 300px;
    }

    .list-sub-tabs-meta {
      @media screen and (max-device-width: 576px) {
        overflow-y: auto;
      }
      .sub-tab-item {
        cursor: pointer;
        @media screen and (max-device-width: 576px) {
          min-width: max-content;
          text-align: center;
        }

        &:not(:first-child) {
          margin-left: 24px;
          @media screen and (max-device-width: 576px) {
            margin-left: 12px;
          }
  
        }
      }
    }
  }
}

.customer-service-container {
  .input-group-text {
    width: 120px;
  }
}

.customization-information-container {

  .tab-content {
    .list-tabs-meta {
      position: relative;
      width: 100%;
      overflow: auto;
      .tab-meta {
        cursor: pointer;
        min-width: 100px;
        padding: 15px 0;
        text-align: center;
      }

      .active-tab {
        border-radius: 4px;
        color: rgb(3, 169, 244);
      }
    }

    .tab-selection-bar-track {
      border-bottom: 2px solid #f0f0f0;
      height: 2px;
      left: 0px;
      bottom: 0px;
      position: absolute;
      .tab-selection-bar {
        // @media screen and (max-device-width: 576px) {
        //   display: none;
        // }
        transition: left 400ms ease 0s, right 400ms ease 0s, width 200ms ease 0s;
        background-color: rgb(3, 169, 244);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px,
          rgba(0, 0, 0, 0.12) 0px 1px 4px;
        display: inline-block;
        height: 2px;
        left: 0px;
        position: absolute;
        top: 0px;
      }
    }
  }
}

.modal-header-promotion-page {
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      cursor: pointer;
      transition: 0.2s all ease-in;
      &:hover {
        color: steelblue;
      }
    }
  }
}

.modal-banner-customization {
  max-width: fit-content;
}

.customization-banners-container {
  .images-showcase {
    width: 100%;
    overflow-x: auto;

    .image-container {
      margin-top: 32px;
      flex: 0 0 30%;
      position: relative;

      &:hover svg {
        opacity: 1;
      }

      &:hover .image {
        opacity: 0.3;
      }

      svg {
        cursor: pointer;
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        text-align: center;
      }

      .image {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: 0.5s ease;
        backface-visibility: hidden;
      }
    }
  }
}

.list-banks-modal-create-manual-dw {
  max-height: 450px;
  width: 280px;
  overflow-y: auto;
  .bank-option {
    &:hover {
      background-color: #deebff;
    }

    font-size: 12px;
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }
}

.confirm-upload-bonus-modal {
  .confirm-upload-bonus-table-container {
    max-height: 850px;
    overflow-y: auto;
  }
}

.running-text-container {
  .ql-snow {
    cursor: text;
  }
}

.popup-ion-transaction {
  .popup-username {
    border-bottom: 1px solid black;
  }

  .bet-options-table {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    .bet-options-row {
      padding: 4px 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }
    }
  }
}

.auto-memo-container {
  .list-tabs {
    .tab-meta {
      cursor: pointer;
      padding: 15px 0;
      text-align: center;
      &:not(:first-child) {
        margin-left: 16px;
      }
    }

    .border-active {
      border-bottom: 2px solid rgb(3, 169, 244);
    }
  }
}

.member-win-loss-report-container {
  .card-body {
    overflow: auto;
    .virtualize-table-wrap {
      @media screen and (max-device-width: 1024px) {
        min-width: 1200px;
      }
      .virtualize-table {
        min-height: 200px;
        color: #23282c;
        border: 1px solid #c8ced3;
        border-spacing: 2px;
    
        .header-class-data-winloss {
          text-align: center;
          vertical-align: bottom;
          font-size: 0.75rem;
          border-bottom-width: 2px;
          padding: 0.5rem;
          height: 50px;
          line-height: 50px;
          border: 1px solid #c8ced3;
          margin-left: 0;
          margin-right: 0;
          text-transform: initial;
        }
    
        .row-data-winloss {
          border: 1px solid #c8ced3;
          &:nth-child(odd) {
            background-color: rgba(0, 0, 0, 0.05);
          }
    
          &:nth-child(even) {
            background-color: #ffffff;
          }
        }
    
        .grid-class-data-winloss {
          .ReactVirtualized__Grid__innerScrollContainer {
            @media screen and (max-device-width: 1024px) {
              overflow-x: auto !important;
            }
          }
        }
    
        .column-data-winloss {
          padding: 6px;
          margin-right: 0;
          border: 1px solid #c8ced3;
          word-break: break-all;
          font-size: 0.75rem;
        }
      }
    }
  
    .footer-wrap {
      width: 100%;
      overflow: auto;
  
      .footer-content {
        width: 100%;
      }
    }
  
    .footer-grand-total {
      font-size: 0.75rem;
      border: 1px solid #c8ced3;
      padding: 6px;
      word-break: break-all;
      vertical-align: top;
    }
  }
}

.image-banners-showcase {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .image-container {
    margin-top: 32px;
    flex: 0 0 250px;
    position: relative;

    &:hover svg {
      opacity: 1;
    }

    &:hover .image {
      opacity: 0.3;
    }

    svg {
      cursor: pointer;
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 10px;
      right: 50px;
      font-size: 20px;
      text-align: center;
    }

    .image {
      opacity: 1;
      display: block;
      width: 100%;
      height: auto;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }
  }
}

.block-numbers-table-list {
  color: #23282c;
  border: 1px solid #c8ced3;
  border-spacing: 2px;

  .header-class-data-winloss {
    display: flex;
    text-align: center;
    vertical-align: bottom;
    font-size: 0.75rem;
    border-bottom-width: 2px;
    padding: 0.5rem;
    height: 50px;
    line-height: 50px;
    border: 1px solid #c8ced3;
    margin-left: 0;
    margin-right: 0;
    text-transform: initial;
  }

  .row-data-winloss {
    display: flex;
    border: 1px solid #c8ced3;
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:nth-child(even) {
      background-color: #ffffff;
    }
  }

  .column-data-winloss {
    border-left: 1px solid #c8ced3;
    border-right: 1px solid #c8ced3;
    padding: 6px;
    margin-right: 0;
    word-break: break-all;
    font-size: 0.75rem;
  }
}

.limit-transfer-bets {
  .input-group-shio {
    .input-group-text {
      width: 100px;
      text-align: center;
    }
  }
}

.template-list-container {
  .line-info-label {
    max-width: 220px;
  }

  .text-decorator {
    font-weight: bold;
    color: steelblue;
    cursor: pointer;
    transition: 0.1s ease-in;

    &:hover {
      color: red;
    }
  }

  .template-item {

    .dropdown-menu-list-wl-companies {
      max-height: 400px;
      overflow-y: auto;
      width: 350px;
    }

    .line-info {
      .template-thumbnail {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
      }
    }
  }

  .template-info {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    // @media screen and (max-device-width: 320px) {
    //   width: 215px;
    // } 
    .square-select-theme {
      width: 30px;
      height: 30px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    .active-color {
      border: 2px solid #e08332;
    }
  }
}

.react-bootstrap-table, .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  min-height: 200px;
  // @media (min-width: 1025px) {
  //   overflow-y: visible;
  // }

  // .dropdown-menu {
  //   @media (max-width: 768px) {
  //     position: static !important;
  //   }
  // }

  .responsive-bootstrap-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: auto !important;

    .order-row {
      width: 20px;
    }

    tbody > tr .form-control {
      @media screen and (max-device-width: 1024px) {
        width: 150px;
      }
    }

    tbody > tr > td > div {
      @media screen and (max-device-width: 1024px) {
        min-width: 120px;
      }
    }

    tbody > tr .sequence-column {
      display: block;
      @media screen and (max-device-width: 1024px) {
        min-width: 30px;
      }
    }
  }
}

.company-create-container {
  .form-group {
    .input-group-prepend {
      max-width: 150px;
      overflow-x: auto;
      @media screen and (max-width: 576px) {
        button {
          font-size: 12px;
        }
      }
    }
  }
}

.daterangepicker {
  @media screen and (max-device-width: 576px) {
    height: 300px !important;
    overflow: auto;
    top: 250px !important;
  }
}

.limit-settings-rate-discount-container {
  .provider-edit-limit-row {
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
  }
}

.bank-list-container {
  .card {
    .card-body {
      table > tbody > tr > td .img-avatar {
        max-width: 150px;
      }
    }
  }
}

.create-bank-account-container {
  .custom-select-bank-list-menu {
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg, 0%, 0% / 10%), 0 4px 11px hsl(0deg, 0%, 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;

    .menu-list {
      max-height: 500px;
      overflow-y: auto;
      padding-bottom: 4px;
      padding-top: 4px;
      position: relative;
      box-sizing: border-box;
      overflow-y: auto;

      .option {
        background-color: transparent;
        color: inherit;
        cursor: default;
        display: block;
        font-size: inherit;
        padding: 8px 12px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        box-sizing: border-box;

        &:hover {
          background-color: #DEEBFF;
        }
      }
    }
  }
}

.togle-commision-header {
  width: 100px;
}

.togle-commision-header-seq {
  width: 80px;
}

.togle-commision-header-name {
  width: 120px;
}
.copy-class-color-icon {
  color: #17920f;
}

.form-bonus-view {
  width: 90%;
  & .text-uppercase {
    text-transform: uppercase;
  }
}

.form-bonus-view .row {
  padding-left: 3%;
}

.form-bonus-view .row .bar-bonus-view {
  display: flex;
  flex-direction: row;
  width: 100%;
  & .bar-bonus-text {
    width: 240px;
  }
  & .bar-bonus-bar {
    width: 180px;
  }
  & .bar-bonus-value {
    margin-left: 5px;
  }
}
