// Variable overrides

$theme-colors: (
  "warning": #f9b115,
  "danger": #e55353
);

$ban-color: #18181a !important !default;

$lock-color: #e55353 !important !default;

$warning-color: #f9b115 !important !default;

$enable-gradients: true;
