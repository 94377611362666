
// Here you can add other styles

.sidebar .nav-link.active {
    background: #20a8d8;
}

.sidebar .nav-link:hover {
    background: #3a4248;
}

.react-bootstrap-daterangepicker-container {
    width: 100%;
}

.table thead th {
    font-size: 0.75rem;
}
.table tbody td {
    font-size: 0.75rem;
}
.table th {
    padding: 0.5rem;
}

.table td {
    padding: 6px;
    word-break: break-all;
}

td > p {
    margin-bottom: 0rem;
}

.table td.td-status-badge {
    min-width: 100px;
}

.input-datepicker {
    background-color: #fff !important;
    opacity: 1;
}

.btn-xsm {
    padding: 0.2rem 0.3rem;
    font-size: 0.75rem;
    line-height: 1;
    border-radius: 0.1rem;
}

.react-bootstrap-table-pagination-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.react-bootstrap-table-pagination > .react-bootstrap-table-pagination-list > ul > li > a{
    padding: .2rem .4rem !important;
}

.react-bootstrap-table-pagination > div > .react-bs-table-sizePerPage-dropdown > button {
    padding: .2rem .4rem !important;
}

.input-mandatory-sign {
    color: red;
}

.modal-dialog {
    max-width: 700px;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: unset;
    }
}

.parent-expand-bar {
    background-color: #e4f6fdc7 !important;
}

.expand-cell-header {
    width: 3%;
}